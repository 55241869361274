import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'
import { HeroH1 } from '../../styles/Utility.styles'

export const Title = styled(HeroH1)`
  grid-column: 2 / 3;
  margin-top: 24px;

  @media ${DEVICE.tabletL} {
    margin-top: 52px;
  }
`

export const SearchField = styled.input`
  grid-column: 2 / 3;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 1.375;
  color: var(--graphic-accent-purple-2);
  background-color: white;
  width: 100%;
  outline: none;
  border-bottom: 2px solid var(--content-background-dark-blue);
  padding-left: 0;

  :focus {
    outline: none;
  }

  @media ${DEVICE.tabletL} {
    font-size: 4.8rem;
  }
`

// TODO: This is a copy/paste job from Footer. Share if possible.
export const SearchButtonContainer = styled.button`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  padding: 21px 0;
  text-decoration-line: none;
  background-color: white;

  svg {
    fill: var(--highlight-blue);
  }

  svg .rollover {
    display: none;
  }

  :hover {
    svg .rollover {
      display: inherit;
    }

    svg .normal {
      display: none;
    }
  }

  @media ${DEVICE.laptopS} {
    padding-left: 23px;
  }
`

export const SearchButtonText = styled.p`
  pointer-events: auto;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: var(--highlight-blue);
  user-select: none;
  padding: 15px 14px 15px 0;
`
