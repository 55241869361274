import React, { useState, useEffect } from 'react'
import * as Styled from './styles/SearchResultsHero.styles'
import { RegionContainer } from '../styles/Utility.styles'
import { ArrowInCircle } from '../Icons'
import { navigate } from 'gatsby'


const CtaButton = () =>
  <Styled.SearchButtonContainer as='button' type='submit'>
    <Styled.SearchButtonText placeholder='Enter search terms'>Search</Styled.SearchButtonText>
    <ArrowInCircle />
  </Styled.SearchButtonContainer>

const SearchResultsHero = ({ queryString }) => {
  const [searchText, setSearchText] = useState(queryString)

  const submitForm = (e) => {
    e.preventDefault()
    navigate(`/search-results/?q=${encodeURI(searchText)}`)
  }

  useEffect(() => {
    setSearchText(queryString)
  }, [queryString])

  return (
    <RegionContainer as='form' onSubmit={submitForm}>
      <Styled.Title>Search Results:</Styled.Title>
      <Styled.SearchField value={searchText} onChange={(e) => {
        setSearchText(e.target.value)
      }}
      />
      <CtaButton />
    </RegionContainer>
  )

}


export default SearchResultsHero