import React from 'react'
import { object } from 'prop-types'
import { linkResolver } from '../util/linkResolver'

import * as Styled from './styles/SearchResultItem.styles'

const SearchResultItem = ({ result, queryString }) => {
  const pruned = result.content.substr(
    result.content.toLowerCase().indexOf(queryString.toLowerCase()),
    280
  )
  const excerpt =
    pruned.length > 20 ? `...${pruned}...` : result.content.substr(0, 280)

  return (
    // <pre>{JSON.stringify(result, false, 2)}</pre>
    <Styled.SearchResultItem
      to={linkResolver({ type: result.type, uid: result.slug })}
    >
      <Styled.SearchResultTitle>{result.title}</Styled.SearchResultTitle>
      <Styled.SearchResultContent>{excerpt}</Styled.SearchResultContent>
    </Styled.SearchResultItem>
  )
}

SearchResultItem.propTypes = {
  result: object,
}

export default SearchResultItem
